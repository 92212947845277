import React, { useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import RollingBanner from '../components/sections/RollingBanner'
import { GlobalEventBus } from '../helpers/eventBus'
import linkResolver from '../utils/linkResolver'
import Seo from '../components/Seo'

const NotFound = ({ data }) => {
	const pageData = data.prismicNotFound.data
	const containerEl = useRef(null)

	return (
		<main className="About NotFound" ref={containerEl}>
			<Seo
				title="Not found"
				description=""
			/>
			<ul className="About__HeaderTexts">
				{pageData.header_texts.map((text, index) => (
					<li className="About__HeaderText" key={`about-header-texts-${index}`}>
						<RichText render={text.text.richText}/>
					</li>
				))}
			</ul>
			<div className="About__BannerContainer">
				<RollingBanner inlineSvg={pageData.inline_svg.text} duration={10} />
			</div>
			<div className="About__FooterBigText">
				<RichText render={pageData.big_text.richText}/>
			</div>
			<div className="About__FooterText">
				<RichText render={pageData.text.richText} linkResolver={linkResolver} />
			</div>
		</main>
	)
}

export default NotFound

export const query = graphql`
	query NotFound {
		prismicNotFound {
			data {
				header_texts {
					text {
						richText
					}
				}
				inline_svg {
					text
				}
				big_text {
					richText
				}
				text {
					richText
				}
			}
		}
		prismicLoader {
			...Loader
		}
	}
`